<template>


  <h3>Přihlašování na tréninky</h3>
  <h5>Individuální tréninky</h5>
    <p>Individuální tréninky po&nbsp;domluvě co chcete řešit - jsou možné venku nebo přímo u&nbsp;vás doma.</p>
    <p>Cena individuálního tréninku je 500 Kč/h a pak 100 Kč/ každá započatá čtvrthodina. V oblasti mezi Nymburkem, Jičínem a Mladou Boleslaví si nepočítám cestu, delší vzdálenosti záleží na domluvě.</p>
  <h5>Skupinové tréninky a socializační procházky v Jičíně a Mladé Boleslavi</h5>
    <!--<p>Vypisuji spíše sporadicky. Pokud jsou, najdete je na <a href="https://clovek-a-pes-spolu.dogres.cz/trainings" target="_blank">mém dogresu.</a></p>
    <p>Pokud vypsané nejsou a měli byste o skupinku zájem, <router-link to="/kontakt">napište mi</router-link>, něco vymyslíme :)</p>-->
    <p>Od dubna 2024 jsem vypsala tréninky a socializační vycházky na pondělní a čtvrteční podvečer do Mladé Boleslavi a úterní/středeční podvečer do Jičína.</p>
    <p>Přihlašování Mladá Boleslav <a href="https://clovek-a-pes-spolu.dogres.cz/trainings?place_id=1099" target="_blank">ZDE</a>.</p>
    <p>Jičín přihlašování <a href="https://clovek-a-pes-spolu.dogres.cz/trainings?place_id=484" target="_blank">ZDE</a>.</p>

  <!-- <h5>Mladá Boleslav - kurzy</h5>
  <p>První běh kurzů v Mladé Boleslavi začne na přelomu října a listopadu. Přihlašování je přes <a href="https://clovek-a-pes-spolu.dogres.cz/trainings" target="_blank">můj dogres</a> 
  a hlásíte se na první tréninkovou lekci. Týden před ní proběhne ještě úvodní teoretická lekce - určená pro školku a základy rodinného psa, nicméně vy, kdo se přihlásíte na přivolávače, si ji určitě můžete také přijít poslechnout :).</p>
  <p>Přihlásit se můžete na následující kurzy:</p>
  <p><a href="https://clovek-a-pes-spolu.dogres.cz/trainings/249544" target="_blank">Školka pro štěňátka</a></p>
  <p><a href="https://clovek-a-pes-spolu.dogres.cz/trainings/249556" target="_blank">Základy rodinného psa pro dospělé psy</a></p>
  <p><a href="https://clovek-a-pes-spolu.dogres.cz/trainings/249565" target="_blank">Přivolávači</a></p>-->
  <h5>Mladá Boleslav - kurzy</h5>
  <p>První kurzy v Mladé Boleslavi začaly <b>8. 10.</b> v tuto chvíli je poslední volné místo na <b>Základy rodinného psa 2.0 - kurz pro psy všeho věku </b><a href="https://clovek-a-pes-spolu.dogres.cz/trainings/338814" target="_blank" >přihlásit se můžete zde</a>
  Pokud byste měli o kurz zájem a už bylo plno, přihlaste se jako náhradník, zkusím najít další možný termín.</p>
  <p>Předpokládám start dalšího běhu na začátku příštího roku. Veškeré informace budou jako vždy zde i na <a href="https://clovek-a-pes-spolu.dogres.cz/trainings" target="_blank">mém dogresu</a> </p>

  <h5>Jičín - kurzy</h5>
  <p>Další kurzy v Jičíně začaly <b>25.9.</b></p> <!--v tuto chvíli je poslední volné místo a <a href="https://clovek-a-pes-spolu.dogres.cz/trainings/334789" target="_blank" >přihlásit se můžete zde</a>-->
  <p>Start dalšího běhu ředpokládám na začátku příštího roku. Veškeré informace budou jako vždy zde i na <a href="https://clovek-a-pes-spolu.dogres.cz/trainings" target="_blank">mém dogresu</a> </p>
    <!--Přihlašování je přes <a href="https://clovek-a-pes-spolu.dogres.cz/trainings" target="_blank">můj dogres</a> 
  a hlásíte se na první tréninkovou lekci. Týden před ní proběhne ještě úvodní teoretická lekce - určená pro školku a základy rodinného psa, nicméně vy, kdo se přihlásíte na přivolávače, si ji určitě můžete také přijít poslechnout :).</p>
  <p>Přihlásit se můžete na následující kurzy:</p>
  <p><a href="https://clovek-a-pes-spolu.dogres.cz/trainings/312360" target="_blank">základy rodinného psa (pro psy všeho věku)</a></p>
  <p><a href="https://clovek-a-pes-spolu.dogres.cz/trainings/249556" target="_blank">Základy rodinného psa pro dospělé psy</a></p>
  <p><a href="https://clovek-a-pes-spolu.dogres.cz/trainings/249565" target="_blank">Přivolávači</a></p>-->

  <h5>ZKO Nymburk - kurzy</h5>
    <p>Současný běh kurzů odstartoval v Nymburce v půlce září. </p>
    <p>Další kurzy začnou v průběhu listopadu.</p> <!--A jakmile budou vypsané, najdete zde propozice i odkazy pro přihlašování.
    <p>Začátek dalšího běhu kurzů předpokládám na přelomu podzimu a zimy. <b>Pokud už teď víte, že chcete na mé kurzy, napište mi, když vás bude víc, začnou dříve :) </b></p>-->
    <p>Vylepšila jsem kurz Základy rodinného psa, tak aby byl pro vás ještě užitečnější, přehlednější a abyste si z něj spolu se svým psím parťákem odnesli více vědomostí, dovedností a zkušeností. proto ho nyní najdete jako <b>Základy rodinného psa 2.0</b></p>
    <p>Přihlašování je přes <a href="https://zko-nymburk.dogres.cz/trainings?trainer_id=924" target="_blank">dogres ZKO</a> 
      Kurzy na rozdíl od jednotlivých lekcí nejsou hrazeny kreditovým systémem</p>
    <p><b>Těším se na vás!</b></p>
    <!--<p><a href="https://zko-nymburk.dogres.cz/trainings/325961" target="_blank">Ošetření se souhlasem - pátek</a></p>-->
    <p><a href="https://zko-nymburk.dogres.cz/trainings/349921" target="_blank">Základy rodinného psa pro&nbsp;dospělé psy a puberťáky&nbsp;2.0</a></p>
    <p><a href="https://zko-nymburk.dogres.cz/trainings/349932" target="_blank">Základy rodinného psa pro&nbsp;štěňátka&nbsp;2.0</a></p>
    <p><a href="https://zko-nymburk.dogres.cz/trainings/349943" target="_blank">Pokračovací kurz pro&nbsp;absolventy Základů rodinného psa</a></p>
    <!--<p><a href="https://zko-nymburk.dogres.cz/trainings/274451" target="_blank">Pro&nbsp;psí důchodce a/nebo citlivky</a></p>
    <p><a href="https://zko-nymburk.dogres.cz/trainings/325952" target="_blank">Ošetření se&nbsp;souhlasem - sobota</a></p>
    <p><a href="https://zko-nymburk.dogres.cz/trainings/325973" target="_blank">Kurz pro reaktivce - jak být s&nbsp;ostatními psy a lidmi v&nbsp;pohodě</a></p>
    <p><a href="https://zko-nymburk.dogres.cz/trainings/305031" target="_blank">Z&nbsp;bojínka hrdinou</a></p>
    <p><a href="https://zko-nymburk.dogres.cz/trainings/325989" target="_blank">Všeobecná sportovní přípravka</a></p>-->

  <h5>ZKO Nymburk - jednotlivé tréninky</h5>
    <p>Na tréninky v rámci <a href="https://zkonymburk.blogspot.com/" target="_blank">ZKO Nymburk</a> se přihlašujte přes <a href="https://zko-nymburk.dogres.cz/trainings?trainer_id=924" target="_blank">dogres ZKO</a>. 
    Na&nbsp;cvičáku funguje kreditový systém, pro přihlášení je tedy nutné mít zakoupené kredity.</p>
    <!--<iframe src="https://zko-nymburk.dogres.cz/trainings?trainer_id=924" width="100%" height="400px"></iframe>-->



</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  setup () {
    useMeta({ title: 'Člověk a pes spolu - trenér psů Zdenka Cihlářová - přihlašování na tréninky a kurzy' })
  },


}
</script>

